import { Injectable } from '@angular/core';
import { BehaviorSubject, first, map, Observable } from 'rxjs';
import { IOrganization } from '../../../models/dtos/get/organization.interface';
import { ITimeZone } from '../../../models/dtos/get/time-zone.interface';

@Injectable({
  providedIn: 'root',
})
export class SharedOrganizationService {
  private _organization: BehaviorSubject<IOrganization> = new BehaviorSubject<IOrganization>(null!);
  public organization$: Observable<IOrganization> = this._organization.asObservable();

  public timeZone: ITimeZone = null!;

  constructor() { }

  public set(organization: IOrganization) {
    this._organization.next(organization);
    this.timeZone = organization.availabilitySetting?.timeZone!;
  }


  public setCalendar(calendarId: string, calendarVersion: string): void {
    this.organization$.pipe(first()).subscribe(response => {
      let calendar = response.calendars.find(c => c.id === calendarId);
      if (!calendar) throw Error("");
      calendar.version = calendarVersion;
    });
  }
}
